//Basic imports
import React, { Component , useState} from 'react';

//Custom IMPORTS: 

//Menu import
import MenuButton from '../Buttons/MenuButton/MenuButton.js';
import '../PageCss/homepage.css';

import HeaderSection from'../HeaderSections/HeaderSection.js'






const HomePage = () => {

    
    return (

        <HeaderSection />
        
        
    )

};



export default HomePage;