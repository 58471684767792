//Basic imports
import React, { Component , useState} from 'react';

//Custom IMPORTS: 

//Menu import
import MenuButton from '../Buttons/MenuButton/MenuButton.js';
import '../PageCss/homepage.css';

import HeaderSection from'../HeaderSections/HeaderContact.js'

const ContactPage = () => {

    
    return (

        <HeaderSection />
      
       
    )

};

export default ContactPage;