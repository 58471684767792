//Basic imports
import React, { Component , useState} from 'react';

//Custom IMPORTS: 

//Menu import
import MenuButton from '../Buttons/MenuButton/MenuButton.js';
import '../PageCss/homepage.css';

import HeaderProjects from'../HeaderSections/HeaderProjects'

const Projects = () => {

    return (
        <HeaderProjects />
    )
};
export default Projects;